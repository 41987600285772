// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artisans-and-world-colombia-js": () => import("/opt/build/repo/src/pages/artisans_and_world/colombia.js" /* webpackChunkName: "component---src-pages-artisans-and-world-colombia-js" */),
  "component---src-pages-artisans-and-world-ecuador-js": () => import("/opt/build/repo/src/pages/artisans_and_world/ecuador.js" /* webpackChunkName: "component---src-pages-artisans-and-world-ecuador-js" */),
  "component---src-pages-artisans-and-world-haiti-js": () => import("/opt/build/repo/src/pages/artisans_and_world/haiti.js" /* webpackChunkName: "component---src-pages-artisans-and-world-haiti-js" */),
  "component---src-pages-artisans-and-world-index-js": () => import("/opt/build/repo/src/pages/artisans_and_world/index.js" /* webpackChunkName: "component---src-pages-artisans-and-world-index-js" */),
  "component---src-pages-artisans-and-world-myanmar-js": () => import("/opt/build/repo/src/pages/artisans_and_world/myanmar.js" /* webpackChunkName: "component---src-pages-artisans-and-world-myanmar-js" */),
  "component---src-pages-ceramics-cups-js": () => import("/opt/build/repo/src/pages/ceramics/cups.js" /* webpackChunkName: "component---src-pages-ceramics-cups-js" */),
  "component---src-pages-ceramics-earrings-js": () => import("/opt/build/repo/src/pages/ceramics/earrings.js" /* webpackChunkName: "component---src-pages-ceramics-earrings-js" */),
  "component---src-pages-ceramics-index-js": () => import("/opt/build/repo/src/pages/ceramics/index.js" /* webpackChunkName: "component---src-pages-ceramics-index-js" */),
  "component---src-pages-contact-form-js": () => import("/opt/build/repo/src/pages/contact/form.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-portfolio-alpamama-js": () => import("/opt/build/repo/src/pages/portfolio/alpamama.js" /* webpackChunkName: "component---src-pages-portfolio-alpamama-js" */),
  "component---src-pages-portfolio-aurelia-js": () => import("/opt/build/repo/src/pages/portfolio/aurelia.js" /* webpackChunkName: "component---src-pages-portfolio-aurelia-js" */),
  "component---src-pages-portfolio-colorama-js": () => import("/opt/build/repo/src/pages/portfolio/colorama.js" /* webpackChunkName: "component---src-pages-portfolio-colorama-js" */),
  "component---src-pages-portfolio-colorbot-js": () => import("/opt/build/repo/src/pages/portfolio/colorbot.js" /* webpackChunkName: "component---src-pages-portfolio-colorbot-js" */),
  "component---src-pages-portfolio-dot-haiti-js": () => import("/opt/build/repo/src/pages/portfolio/dot_haiti.js" /* webpackChunkName: "component---src-pages-portfolio-dot-haiti-js" */),
  "component---src-pages-portfolio-fique-js": () => import("/opt/build/repo/src/pages/portfolio/fique.js" /* webpackChunkName: "component---src-pages-portfolio-fique-js" */),
  "component---src-pages-portfolio-haiti-js": () => import("/opt/build/repo/src/pages/portfolio/haiti.js" /* webpackChunkName: "component---src-pages-portfolio-haiti-js" */),
  "component---src-pages-portfolio-hugo-unite-js": () => import("/opt/build/repo/src/pages/portfolio/hugo_unite.js" /* webpackChunkName: "component---src-pages-portfolio-hugo-unite-js" */),
  "component---src-pages-portfolio-index-js": () => import("/opt/build/repo/src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-luca-js": () => import("/opt/build/repo/src/pages/portfolio/luca.js" /* webpackChunkName: "component---src-pages-portfolio-luca-js" */),
  "component---src-pages-portfolio-paloquemao-js": () => import("/opt/build/repo/src/pages/portfolio/paloquemao.js" /* webpackChunkName: "component---src-pages-portfolio-paloquemao-js" */),
  "component---src-pages-portfolio-procolorbot-js": () => import("/opt/build/repo/src/pages/portfolio/procolorbot.js" /* webpackChunkName: "component---src-pages-portfolio-procolorbot-js" */),
  "component---src-pages-portfolio-wabi-js": () => import("/opt/build/repo/src/pages/portfolio/wabi.js" /* webpackChunkName: "component---src-pages-portfolio-wabi-js" */),
  "component---src-pages-press-js": () => import("/opt/build/repo/src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-research-index-js": () => import("/opt/build/repo/src/pages/research/index.js" /* webpackChunkName: "component---src-pages-research-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

